import Landing from './page/Landing/Landing';
import useObservable from './utils/useObservable';
import LanguageService from './service/Language';
import './scss/global.scss';

function App() {
    useObservable(LanguageService.current, LanguageService.current.getValue());
    return (
        <div className='App'>
            <Landing />
        </div>
    );
}

export default App;
