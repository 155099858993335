let StorageProvider: Storage = {
    getItem: function (key: string): string | null {
        if (key !== '') {
            throw new Error('No storage');
        }

        return null;
    },
    setItem: function (key: string, value: any): void {},
    removeItem: function (key: string): void {},
    clear: function (): void {},
    length: NaN,
    key: () => null,
};

if (localStorage) {
    StorageProvider = localStorage;
}

function set(key: string, value: any) {
    StorageProvider.setItem(key, JSON.stringify(value));
}

function get(key: string): any {
    const value = StorageProvider.getItem(key);

    if (value !== null) {
        try {
            return JSON.parse(value);
        } catch (e) {
            throw Error('Invalid Storage data, key: ' + key);
        }
    } else {
        throw Error('Undefined Storage key: ' + key);
    }
}

function remove(key: string) {
    StorageProvider.removeItem(key);
}

function clear() {
    StorageProvider.clear();
}

const Storage = { get, set, remove, clear };

export default Storage;
