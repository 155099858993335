import { translate as t } from '../../service/Language';
import { ReactComponent as IconTelegram } from '../../svg/icons/icon-telegram.svg';
import { ReactComponent as IconTelegramCircle } from '../../svg/icons/icon-telegram-circle.svg';
import './Contacts.scss';

const Contacts = () => {
    return (
        <div className='contacts'>
            <div className='contacts__top'>
                <div className='contacts-telegram__title'>
                    {t('partners.contacts.telegram.title')}
                </div>
                <a href='https://t.me/+n53vvQw5bzc3ZTA6' className='contacts-telegram__btn'>
                    {t('partners.contacts.telegram.btn')}
                    <IconTelegram />
                </a>
            </div>
            <div className='contacts__bottom'>
                <div className='contacts__title'>{t('partners.contacts.title')}</div>
                <div className='contacts__social'>
                    <a href='https://t.me/ShamGlory' className='contacts__social_item'>
                        <IconTelegramCircle />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
