import { translate as t } from '../../service/Language';
import agreementDoc from './agreement.pdf';
import './Footer.scss';

const Footer = () => {
    return (
        <div className='footer'>
            <a href={agreementDoc} target='_blank' className='footer__link' rel="noreferrer">
                {t('partners.cooperation.title')}
            </a>
        </div>
    );
};

export default Footer;
