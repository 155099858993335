import { ReactComponent as IconLogo } from '../../svg/icons/icon-logo.svg';
import LanguageSelect from '../common/LanguageSelect/LanguageSelect';
import { translate as t } from '../../service/Language';
import './Header.scss';
import { useEffect, useRef } from 'react';

const Header = () => {
    const headerRef = useRef<HTMLDivElement>(null);
    const detectHeaderScrolled = (e: Event) => {
        if (window.scrollY > 0 && headerRef.current) {
            headerRef.current.classList.add('header__scrolled');
        } else if (headerRef.current?.classList.contains('header__scrolled')) {
            headerRef.current.classList.remove('header__scrolled');
        }
    };
    useEffect(() => {
        document.addEventListener('scroll', detectHeaderScrolled);
        return () => {
            document.removeEventListener('scroll', detectHeaderScrolled);
        };
    }, []);

    return (
        <header className='header' ref={headerRef}>
            <div className='header__content'>
                <div className='header__logo'>
                    <IconLogo />
                </div>
                <div className='header__controls'>
                    <a
                        href='https://partner.glory.partners/login'
                        className='header__controls_signin'
                    >
                        {t('partners.header.signin')}
                    </a>
                    <a
                        href='https://partner.glory.partners/registration'
                        className='header__controls_signup'
                    >
                        {t('partners.header.signup')}
                    </a>
                    <LanguageSelect className='header__language' />
                </div>
            </div>
        </header>
    );
};

export default Header;
