import classNames from 'classnames';
import { createRef } from 'react';

import LanguageService, { LanguageType } from '../../../service/Language';
import RxComponent from '../../RxComponent/RxComponent';
import { BN, EN, RU, UZ } from '../images';
import ButtonSelect from './ButtonSelect/ButtonSelect';

import './LanguageSelect.scss';

type LanguageSelectProps = {
    className: string;
    showArrow?: boolean;
    size?: 'small' | 'big';
};

type LanguageSelectState = {
    active: boolean;
    language: LanguageType;
};

export type LanguageImageTitle = {
    img: string;
    title: string;
};

class LanguageSelect extends RxComponent<LanguageSelectProps, LanguageSelectState> {
    state = {
        active: false,
        language: LanguageService.current.getValue(),
    };

    handler = {
        toggle: this.toggle.bind(this),
        select: this.select.bind(this),
        onLanguageChange: this.onLanguageChange.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    list: LanguageImageTitle[] = [
        { img: RU, title: 'RU' },
        { img: BN, title: 'BN' },
        { img: EN, title: 'EN' },
        { img: UZ, title: 'UZ' },
    ];

    close() {
        this.setState({ active: false });
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    open() {
        this.setState({ active: true });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    toggle() {
        const { active } = this.state;

        if (active) {
            this.close();
        } else {
            this.open();
        }
    }

    select(language: LanguageType) {
        this.close();
        LanguageService.setLanguage(language);
    }

    onLanguageChange(language: LanguageType) {
        this.setState({ language });
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    componentDidMount() {
        this.subscribe(LanguageService.current, { next: this.handler.onLanguageChange });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { className, size = 'big' } = this.props;
        const { language, active } = this.state;

        const _className = classNames(className, {
            'language-select': true,
            'language-select____active': active,
            'language-select____small': size === 'small',
            [className]: !!className,
        });

        let match = this.list.find(item => item.title === language.title);
        if (!match) {
            match = this.list[0];
        }
        const LanguageImage = match.img;

        return (
            <div className={_className} ref={this.ref.self}>
                <button
                    type='button'
                    className='language-select__current'
                    onClick={this.handler.toggle}
                >
                    <img
                        className='language-select__img'
                        width={size === 'big' ? 28 : 20}
                        height={size === 'big' ? 28 : 20}
                        src={LanguageImage}
                        alt={language.title}
                    />
                    <span className='language-select__title'>{language.title}</span>
                </button>

                <div className='language-select__list'>
                    {LanguageService.list
                        .sort((a, b) =>
                            a.title === language.title ? -1 : b.title === language.title ? 1 : 0,
                        )
                        .map(language => (
                            <ButtonSelect
                                language={language}
                                size={size}
                                onClick={this.handler.select}
                                key={language.title}
                                list={this.list}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default LanguageSelect;
