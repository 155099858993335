import { ReactComponent as IconVisa } from '../../svg/icons/icon-visa.svg';
import { ReactComponent as IconMastercard } from '../../svg/icons/icon-mastercard.svg';
import { ReactComponent as IconBitcoin } from '../../svg/icons/icon-bitcoin.svg';
import { ReactComponent as IconWireTransfer } from '../../svg/icons/icon-wire-transfer.svg';
import { ReactComponent as IconTether } from '../../svg/icons/icon-tether.svg';
import './Payments.scss';

const Payments = () => {
    return (
        <div className='payments'>
            <div className='payments__block'>
                <div className='payments__item wire'>
                    <IconWireTransfer />
                </div>
                <div className='payments__item tether'>
                    <IconTether />
                </div>
                <div className='payments__item bitcoin'>
                    <IconBitcoin />
                </div>
                <div className='payments__item mastercard'>
                    <IconMastercard />
                </div>
                <div className='payments__item visa'>
                    <IconVisa />
                </div>
            </div>
            <div className='payments__dollar_top'></div>
            <div className='payments__dollar_bottom'></div>
            <div className='payments__card'></div>
        </div>
    );
};

export default Payments;
