import { translate as t } from '../../service/Language';
import { ReactComponent as IconRoi } from '../../svg/icons/icon-roi.svg';
import { ReactComponent as IconDollar } from '../../svg/icons/icon-dollar.svg';
import { ReactComponent as IconHeadphones } from '../../svg/icons/icon-headphones.svg';
import { ReactComponent as IconGrow } from '../../svg/icons/icon-grow.svg';
import { ReactComponent as IconBrowser } from '../../svg/icons/icon-browser.svg';
import { ReactComponent as IconHands } from '../../svg/icons/icon-hands.svg';
import './Features.scss';

const FEATURES = [
    {
        id: 1,
        icon: <IconRoi />,
        title: 'partners.features.roi.title',
    },
    {
        id: 2,
        icon: <IconDollar />,
        title: 'partners.features.income.title',
    },
    {
        id: 3,
        icon: <IconHeadphones />,
        title: 'partners.features.manager.title',
    },
    {
        id: 4,
        icon: <IconGrow />,
        title: 'partners.features.conversion.title',
    },
    {
        id: 5,
        icon: <IconBrowser />,
        title: 'partners.features.landing.mobile.title',
    },
    {
        id: 6,
        icon: <IconHands />,
        title: 'partners.features.conditions.title',
    },
];

const Features = () => {
    return (
        <div className='features'>
            <div className='features__title'>
                <span>{t('partners.features.title')}</span>
            </div>
            <div className='features__grid'>
                {FEATURES.map(feature => (
                    <div key={feature.id} className='features__item'>
                        <div className='features__item_icon'>{feature.icon}</div>
                        <div className='features__item_title'>{t(feature.title)}</div>
                    </div>
                ))}
            </div>
            <div className='features__grid mobile'>
                {FEATURES.map(feature => (
                    <div key={feature.id} className='features-mobile__item'>
                        <div className='features-mobile__item_icon'>{feature.icon}</div>
                        <div className='features-mobile__item_title'>{t(feature.title)}</div>
                    </div>
                ))}
            </div>
            <div className='features__action'>
                <a
                    className='features__action_btn'
                    href='https://partner.glory.partners/registration'
                >
                    {t('partners.action.join')}
                </a>
            </div>
        </div>
    );
};

export default Features;
