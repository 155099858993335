import { translate as t } from '../../service/Language';
import './Conveyor.scss';

const Conveyor = () => {
    return (
        <div className='conveyor'>
            <div className='conveyor__header'>
                <h1 className='conveyor__title'>{t('partners.conveyor.title')}</h1>
                <div className='conveyor__subtitle'>{t('partners.conveyor.subtitle')}</div>
                <div className='conveyor__subtitle mobile'>
                    {t('partners.conveyor.mobile.subtitle')}
                </div>
            </div>
            <div className='conveyor__about'>
                <h3 className='conveyor__about_title'>{t('partners.about.title')}</h3>
                <div className='conveyor__about_subtitle'>{t('partners.about.description')}</div>
            </div>
            <div className='conveyor__bg'></div>
            <div className='conveyor__dollars_big'></div>
            <div className='conveyor__dollars_small'></div>
            <div className='conveyor__chip'></div>
            <div className='conveyor__dollar'></div>
            <div className='conveyor__points'></div>
            <div className='conveyor__diamond'></div>
            <div className='conveyor__coin'></div>
        </div>
    );
};

export default Conveyor;
