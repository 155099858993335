import { BehaviorSubject } from 'rxjs';

import bn from '../lang/bn.json';
import en from '../lang/en.json';
import flb from '../lang/flb.json';
import ru from '../lang/ru.json';
import uz from '../lang/uz.json';
import Storage from './Storage';

type Dictionary = Record<string, string>;

type LanguageType = {
    title: string;
    dictionary: Dictionary;
};

class Language {
    public static STORAGE_KEY = 'language';

    public current: BehaviorSubject<LanguageType>;

    list: LanguageType[] = [
        { title: 'EN', dictionary: en as Dictionary },
        { title: 'RU', dictionary: ru as Dictionary },
        { title: 'BN', dictionary: bn as Dictionary },
        { title: 'UZ', dictionary: uz as Dictionary },
    ];

    constructor() {
        let locale = this.getLang();

        try {
            locale = Storage.get(Language.STORAGE_KEY) as string;
        } catch (e) {
            Storage.set(Language.STORAGE_KEY, locale);
        }

        const map = new Map<string, LanguageType>(
            this.list.map(language => [language.title, language]),
        );
        const language = map.get(locale) ?? this.list[0];

        this.current = new BehaviorSubject(language);
    }

    getLang() {
        const locale = navigator.language;
        let [lang] = locale.split('-');

        const searchParams = new URLSearchParams(window.location.search);
        const urlLang = searchParams.get('lang');

        if (urlLang) {
            lang = urlLang;
        }

        return lang.toUpperCase();
    }

    setLanguage(language: LanguageType) {
        Storage.set(Language.STORAGE_KEY, language.title);
        this.current.next(language);
    }

    translateInto(key: string, language: LanguageType): string {
        let string = '';
        const fallback = flb as Dictionary;

        if (language.dictionary.hasOwnProperty(key)) {
            string = language.dictionary[key];
        } else {
            string = fallback.hasOwnProperty(key) ? fallback[key] : key;
        }

        return string;
    }

    translate(key: string) {
        return this.translateInto(key, this.current.getValue());
    }
}

const LanguageService = new Language();

function translate(key: string): string {
    return LanguageService.translate(key);
}

export { translate };
export type { LanguageType };
export default LanguageService;
