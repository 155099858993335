import Contacts from '../../component/Contacts/Contacts';
import Conveyor from '../../component/Conveyor/Conveyor';
import Features from '../../component/Features/Features';
import Payments from '../../component/Payments/Payments';
import Header from '../../component/Header/Header';
import Footer from '../../component/Footer/Footer';
import './Landing.scss';

const Landing = () => {
    return (
        <div className='landing'>
            <Header />
            <div className='landing__container'>
                <div className='landing__conveyor'>
                    <Conveyor />
                </div>
                <div className='landing__features'>
                    <Features />
                </div>
                <div className='landing__payments'>
                    <Payments />
                </div>
                <div className='landing__contacts'>
                    <Contacts />
                </div>
            </div>
            <Footer />
            <div className='landing__chip_left'></div>
            <div className='landing__card_left'></div>
            <div className='landing__points_left_first'></div>
            <div className='landing__points_left_second'></div>
            <div className='landing__coin_right'></div>
            <div className='landing__card_right'></div>
            <div className='landing__points_right_second'></div>
            <div className='landing__points_right_third'></div>
            <div className='landing__cup'></div>
        </div>
    );
};

export default Landing;
